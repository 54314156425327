import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_2 from '../../images/leistungen/img2.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Mesotherapie | Dr. K. Kostka in Bochum" description="Dr. K. Kostka bietet Mesotherapien mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Mesotherapie"} subtitle={"Vitalstoffe für die Haut"} frontImage={img_leistung_2}></HeadlineWithImage>
          </Col>
        </Row>
        <p>
          Die ästhetische Mesotherapie verwendet Wirkstoffkombinationen überwiegend auf natürlicher Basis aus Antioxidantien, Vitaminen, Mineralien und Aminosäuren. Sie ist sanft, schonend, gut verträglich und kostengünstiger als die Verfahren mit Hyaluron oder Botox©.
          <br/>
          <br/>
          Die Vitalstoffcocktails werden durch Mikroinjektionen oberflächlich nur wenige mm tief in die Haut gebracht. Die dabei verwendeten Nadeln sind extrem dünn und besonders geschliffen.
          Die Hautdepots geben ihre regenerativen Wirkstoffe nach und nach ab. So kann trotz geringer Dosierung eine langanhaltende Wirkung erzeugt werden. 
          <br/>
          <br/>
          In der Regel sind 3-5 Anwendungen mit einem wöchentlichen Abstand notwendig.
          <br />
          <br />
          Mit meiner langjährigen Erfahrung in der Mesotherapie und auch in der Behandlung mit Hyaluron und Botox© berate ich Sie gerne ganz individuell bei weiteren Fragen. Vereinbaren Sie dazu gerne einen Termin in meiner Praxis mitten in Bochum.
        </p>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;
